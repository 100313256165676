import {
  BookingDetailsDefaultValues,
  DefaultValuesDetailsForm,
  DetailsFormValues,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import React, { useEffect, useState } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import Switch from "@mui/material/Switch";
import { AddDocumentsWithPreview } from "../../components/UI/AllWebCheckInForms/DocumentUpload";
import AppButton from "../../components/UI/AppButtons";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import AppStandardButton from "../../components/UI/AppButtons";
import { AxiosResponse } from "axios";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import { BookingApi } from "../../API/Apis/BookingApi";
import { ByReservationNumberForm } from "../../components/UI/AllWebCheckInForms/ReservationDetailsByReservationNumber";
import { ByVoucherNumberForm } from "../../components/UI/AllWebCheckInForms/ReservationDetailsByVoucherForm";
import { CompanyForm } from "../../components/UI/AllWebCheckInForms/CompanyForm";
import { CreateNewBooking } from "../../@types/DTO/Booking";
import { CustomerDetailsResponse_2 } from "../../@types/DTO/ApiResponses";
import { DriverAddForm } from "../../components/Layouts/DriverAddForm/DriverAddForm";
import { IndividualUserForms } from "../../components/UI/AllWebCheckInForms/IndividualUserForms";
import AppModal, { Modal } from "../../components/UI/AppModal";
import { PrivacyPolicy } from "../../@types/DTO/privacyPolicy";
import { SoleTraderForm } from "../../components/UI/AllWebCheckInForms/SoleTraderForm";
import { StandardCard } from "../../components/UI/AppCards";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../components/UI/AppModal/DialogBox";
import { FieldArray } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
function WebCheckInPage(): JSX.Element {
  type RESERVATION_TYPE = "I have Voucher Number" | "I have Reservation Number";
  const [reservationType, setReservationType] = useState<RESERVATION_TYPE>(
    "I have Reservation Number"
  );
  const ReservationOptions: Array<RESERVATION_TYPE> = [
    "I have Reservation Number",
    "I have Voucher Number",
  ];
  // const [isAgree, setAgree] = useState<boolean>(false);
  // const checkBoxHandler = () => {
  //   setAgree(!isAgree);
  // };
  // const privacyPolicyAgreements = () => {
  //   const btnHandler = () => {};
  // };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [initialCustomerDetails, setInitialCustomerDetails] =
    useState<DetailsFormValues>({
      ...DefaultValuesDetailsForm,
      // reservationDate: new Date("2022-08-15"), (For Fleet manager)
      // reservationNumber: "22",
      // reservationPrefix: "ROME",

      // reservationDate: new Date("2023-01-23"), //For Auto one
      // reservationNumber: "7297",
      // reservationPrefix: "HQ",
    });
  const [currentCustomerDetails, setCurrentCustomerDetails] =
    useState<DetailsFormValues>({
      ...initialCustomerDetails,
    });
  const [reservationDetails, setReservationDetails] =
    useState<CreateNewBooking>({
      ...BookingDetailsDefaultValues,
    });

  const [initialDriverValues, setInitialDriverValues] = useState<
    DetailsFormValues[]
  >([]);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentDriverDetails, setCurrentDriverDetails] =
    useState<DetailsFormValues>({ ...DefaultValuesDetailsForm });
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [allDriverDetails, setAllDriverDetails] = useState<DetailsFormValues[]>(
    []
  );
  const [allPrivacyPolicy, setAllPrivacyPolicy] = useState<
    PrivacyPolicy["privacyPolicy"]
  >([]);
  const [selectedPrivacyPolicy, setSelectedPrivacyPolicy] = useState<string[]>(
    []
  );
  const [openDriverAddDialog, setOpenDriverAddDialog] =
    useState<boolean>(false);
  const [addingDriver, setAddingDriver] = useState<boolean>(false);
  const [document, setDocument] = useState<File[]>([]);
  const [currentModifyingDriverIndex, setCurrentModifyingDriverIndex] =
    useState<number>(-1);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const onRequestClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };

  const DialogueConfirmationForAddingMoreDriver = async () => {
    if (currentStep == 1 && allDriverDetails.length < 3) {
      if (currentCustomerDetails.customerType.toLowerCase() == "company") {
        setAddingDriver(true);
      } else {
        setOpenDriverAddDialog(true);
      }
      return Promise.reject(); // is adding Driver
    }
    return Promise.resolve(); // is not adding driver
  };
  const [showWebCheckIn, setShowWebCheckIn] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(true);

  const driverButtonsStytlingConfig = [
    {
      icon: "/assets/icons/blue-driver-icon.png",
      color: "#004188",
    },
    {
      icon: "/assets/icons/yellow-driver-icon.png",
      color: "#F9ca01",
    },
    {
      icon: "/assets/icons/red-driver-icon.png",
      color: "#bb3333",
    },
  ];

  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowWebCheckIn(e.webCheckIn);
    });
  }, []);
  const FormButtons = () => {
    return (
      <div className="box-border">
        {errorMessage ? (
          <p className="text-red-800 bg-red-200 box-border px-4 py-1 text-center rounded-lg">
            {errorMessage}
          </p>
        ) : (
          ""
        )}

        {currentStep == 1 && (
          <>
            <div>
              {" "}
              <p className="text-black text-md pb-2 font-semibold">
                You can add maximum of three drivers. Do you want to add driver?
              </p>
            </div>
            <div className="flex gap-2 flex-wrap">
              {allDriverDetails.map((data, index) => {
                return (
                  <AppStandardButton
                    key={index}
                    variant="text"
                    clickHandler={() => {
                      setAddingDriver(true);
                      setCurrentDriverDetails(allDriverDetails[index]);
                      setCurrentModifyingDriverIndex(index);
                    }}
                  >
                    <img
                      src={driverButtonsStytlingConfig[index].icon}
                      className="w-5 h-5 mr-2"
                    />
                    <p
                      className={`text-[${driverButtonsStytlingConfig[index].color}] normal-case`}
                    >
                      {t("Edit Driver")}

                      {index + 1}
                    </p>
                  </AppStandardButton>
                );
              })}
              {allDriverDetails.length < 3 && (
                <div
                  className={`w-fit cursor-pointer text-md text-[${
                    driverButtonsStytlingConfig[
                      Math.max(allDriverDetails.length, 1) - 1
                    ].color
                  }]`}
                  onClick={() => {
                    setAddingDriver(true);
                  }}
                >
                  <div className="flex items-center mt-1">
                    <img
                      src="/assets/icons/red-driver-icon.png"
                      className="w-5 h-5 mr-2"
                    />
                    {/* <p className="text-red-800">
              {allDriverDetails.length}/3 {t("Drivers Added")}
              <input type="text" style={{ height: 0, width: 0 }} />
            </p> */}
                    <p className="text-red-800 font-semibold">
                      {t("Add Driver")}
                      <input type="text" style={{ height: 0, width: 0 }} />
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className={"flex flex-row flex-wrap box-border pt-8 gap-2"}>
          {currentStep > 0 && (
            <AppStandardButton
              variant="outlined"
              color="primary"
              className="w-1/2"
              clickHandler={() => {
                setCurrentStep((prev) => Math.max(prev - 1, 0));
              }}
            >
              <p className="text-black normal-case text-center w-full font-medium text-xl">
                {t("Prev")}
              </p>
            </AppStandardButton>
          )}
          {/* <div className={"grow"}></div> */}

          <AppStandardButton
            disabled={
              currentStep == 2 &&
              allPrivacyPolicy.length !== selectedPrivacyPolicy.length
            }
            loading={isSaving}
            className={"w-fit grow"}
            color={"webCheckInNextButton"}
            type={"submit"}
          >
            <p className="normal-case text-center w-full font-medium text-xl text-[#ffffff]">
              {t("Next")}
            </p>
          </AppStandardButton>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (
      currentStep > 0 &&
      !(currentCustomerDetails.firstName || currentCustomerDetails.lastName)
    ) {
      // setCurrentStep(0);
    }
    if (currentCustomerDetails.customerType.toLowerCase() === t("company")) {
      setCurrentCustomerDetails((prev) => ({
        ...prev,
        isSignatoryTheMainDriver: "NO",
      }));
    }
  }, [currentStep, currentCustomerDetails]);
  function setDriverDetailsAndInitialDriverIndexAtIndex(
    driverDetails: CustomerDetailsResponse_2,
    index: number
  ) {
    setAllDriverDetails((prev: DetailsFormValues[]) => {
      prev[index] = {
        ...DefaultValuesDetailsForm,
        ...driverDetails,
        isDriver: !driverDetails.isDriver ? "YES" : "NO",
        reservationNumber: JSON.stringify(reservationDetails.reservationId),
      };
      return [...prev];
    });

    setInitialDriverValues((prev) => {
      prev[index] = {
        ...DefaultValuesDetailsForm,
        ...driverDetails,
        isDriver: !driverDetails.isDriver ? "YES" : "NO",
        reservationNumber: JSON.stringify(reservationDetails.reservationId),
      };
      return [...prev];
    });
  }
  useEffect(() => {
    if (reservationDetails.driver1Id) {
      BookingApi.getCustomerDetails(reservationDetails.driver1Id).then((e) => {
        setDriverDetailsAndInitialDriverIndexAtIndex(
          {
            ...e.data,
          },
          0
        );
      });
    }
    if (reservationDetails.driver2Id) {
      BookingApi.getCustomerDetails(reservationDetails.driver2Id).then((e) => {
        setDriverDetailsAndInitialDriverIndexAtIndex(e.data, 1);
      });
    }
    if (reservationDetails.driver3Id) {
      BookingApi.getCustomerDetails(reservationDetails.driver3Id).then((e) => {
        setDriverDetailsAndInitialDriverIndexAtIndex(e.data, 2);
      });
    }
  }, [reservationDetails]);
  useEffect(() => {
    dispatch(BOOKING_ACTIONS.setSessionExpiryTime(0));
  }, []);

  /**
   *
   *  FUNCTIONS
   *
   */

  async function updateReservationDetails(customerDetails: DetailsFormValues) {
    await setIsSaving(true);
    await setCurrentCustomerDetails({ ...customerDetails });
    await BookingApi.updateCustomerDetails(
      reservationDetails.customerId,
      currentCustomerDetails
    );
    await BookingApi.addDrivers(reservationDetails.reservationId, [
      allDriverDetails[0],
      allDriverDetails[1],
      allDriverDetails[2],
    ]);
    await setCurrentStep(currentStep + 1);
    await setIsSaving(false);
  }
  async function getBookingDetailsByReservationNumber(): Promise<void> {
    try {
      setErrorMessage("");
      if (currentCustomerDetails.reservationDate == null) {
        setErrorMessage("Reservation Date not provided");
        return;
      }
      const reservationApiResponse: AxiosResponse<CreateNewBooking> =
        await BookingApi.getBookingDetailsByBookingDateAndReservationCode(
          currentCustomerDetails.reservationPrefix || "",
          currentCustomerDetails.reservationNumber || "",
          currentCustomerDetails.reservationDate as Date
        );
      if (reservationApiResponse.data.status === "error") {
        setModalMessage(
          reservationApiResponse.data.msg || "Something went wrong"
        );
        setOpenModal(true);
      } else {
        getPrivacyPolicy(reservationApiResponse.data.reservationId);
        const customerDetails: AxiosResponse<CustomerDetailsResponse_2> =
          await BookingApi.getCustomerDetails(
            reservationApiResponse.data.customerId
          );
        const tempValues: DetailsFormValues = {
          ...currentCustomerDetails,
          ...customerDetails.data,
          isDriver: customerDetails.data.isDriver ? "YES" : "NO",
          customerType: customerDetails.data.customerType.toUpperCase(),
        };
        setInitialCustomerDetails({ ...tempValues });

        setReservationDetails({ ...reservationApiResponse.data });
        await setCurrentCustomerDetails({ ...tempValues });

        await setCurrentStep((prev) => Math.min(prev + 1, allStep.length - 1));
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getBookingDetailsByVoucherCodeAndFullName(): Promise<void> {
    try {
      setErrorMessage("");
      const reservationApiResponse =
        await BookingApi.getBookingDetailsByVoucherCode(
          currentCustomerDetails.reservationVoucher || "",
          currentCustomerDetails.firstName || "",
          currentCustomerDetails.lastName || "",
          currentCustomerDetails.companyName || ""
        );
      if (reservationApiResponse?.data?.status == "error") {
        setOpenModal(true);
        setModalMessage(
          reservationApiResponse.data.msg || "Something went wrong"
        );
      } else {
        getPrivacyPolicy(reservationApiResponse.data.reservationId);
        const customerDetails: AxiosResponse<CustomerDetailsResponse_2> =
          await BookingApi.getCustomerDetails(
            reservationApiResponse.data.customerId
          );
        const tempValues: DetailsFormValues = {
          ...currentCustomerDetails,
          ...customerDetails.data,
          isDriver: customerDetails.data.isDriver ? "YES" : "NO",
          customerType: customerDetails.data.customerType.toUpperCase(),
        };
        setInitialCustomerDetails({ ...tempValues });
        setReservationDetails({
          ...reservationApiResponse.data,
        });
        await setCurrentCustomerDetails({ ...tempValues });
        await setCurrentStep((prev) => Math.min(prev + 1, allStep.length - 1));
      }
    } catch (err) {
      console.log(err);
    }
  }
  function getPrivacyPolicy(reservationId: number) {
    BookingApi.getPrivacyPolicy(reservationId).then((e) => {
      setAllPrivacyPolicy(e.data.privacyPolicy);
    });
  }
  const allStep = [
    {
      label: t("Reservation/Voucher details"),
      component:
        t(reservationType) === t("I have Reservation Number") ? (
          <ByReservationNumberForm
            onSubmit={async (e) => {
              await setIsSaving(true);
              await setCurrentCustomerDetails({
                ...currentCustomerDetails,
                reservationPrefix: e.reservationPrefix,
                reservationNumber: e.reservationNumber,
                reservationDate: e.reservationDate,
              });
              await getBookingDetailsByReservationNumber();
              await setIsSaving(false);
            }}
            onChange={(e) => {
              setCurrentCustomerDetails((prev) => {
                return { ...prev, ...e };
              });
            }}
            values={currentCustomerDetails}
          >
            <FormButtons />
          </ByReservationNumberForm>
        ) : (
          <ByVoucherNumberForm
            onSubmit={async (e) => {
              await setIsSaving(true);
              await setCurrentCustomerDetails({
                ...currentCustomerDetails,
                reservationVoucher: e.reservationVoucher,
                firstName: e.firstName,
                lastName: e.lastName,
                companyName: e.companyName,
              });
              await getBookingDetailsByVoucherCodeAndFullName();
              await setIsSaving(false);
            }}
            onChange={(e) => {
              setCurrentCustomerDetails((prev) => {
                return { ...prev, ...e };
              });
            }}
          >
            <FormButtons />
          </ByVoucherNumberForm>
        ),
      onSubmit: async () => {},
    },
    {
      label: t("Customer Details"),
      component: (
        <div>
          <AppRadioButton
            label={t("Are you an Individual, company or sole trader")}
            value={t(currentCustomerDetails.customerType.toLowerCase())}
            readOnly={true}
            options={["Individual", "Company", "Sole Trader"].map((el) => {
              return t(el.toLowerCase());
            })}
          />

          {t(currentCustomerDetails.customerType.toLowerCase()).toLowerCase() ==
            t("individual").toLowerCase() && (
            <IndividualUserForms
              onSubmit={async (e) => {
                setIsSaving(true);
                await BookingApi.updateCustomerDetails(
                  reservationDetails.customerId,
                  currentCustomerDetails
                )
                  .then(() => {
                    return DialogueConfirmationForAddingMoreDriver();
                  })
                  .then(async () => {
                    await BookingApi.addDrivers(
                      reservationDetails.reservationId,
                      [
                        allDriverDetails[0],
                        allDriverDetails[1],
                        allDriverDetails[2],
                      ]
                    );
                    setCurrentStep(currentStep + 1);
                  })
                  .finally(() => {
                    setIsSaving(false);
                  });
              }}
              initialValues={initialCustomerDetails}
              values={currentCustomerDetails}
              onChange={(e) => {
                setCurrentCustomerDetails((prev: DetailsFormValues) => {
                  return { ...prev, ...e };
                });
              }}
            >
              <FormButtons />
            </IndividualUserForms>
          )}

          {t(currentCustomerDetails.customerType.toLowerCase()).toLowerCase() ==
            t("company").toLowerCase() && (
            <CompanyForm
              onSubmit={async (e) => {
                await updateReservationDetails({
                  ...currentCustomerDetails,
                  ...e,
                });
              }}
              initialValues={initialCustomerDetails}
              values={currentCustomerDetails}
              onChange={(e) => {
                setCurrentCustomerDetails((prev) => {
                  return { ...prev, ...e };
                });
              }}
            >
              <FormButtons />
            </CompanyForm>
          )}
          {t(currentCustomerDetails.customerType.toLowerCase()).toLowerCase() ==
            t("sole trader").toLowerCase() && (
            <SoleTraderForm
              onSubmit={async (e) => {
                await updateReservationDetails({
                  ...currentCustomerDetails,
                  ...e,
                });
              }}
              initialValues={initialCustomerDetails}
              values={currentCustomerDetails}
              onChange={(e) => {
                setCurrentCustomerDetails((prev) => {
                  return { ...prev, ...e };
                });
              }}
            >
              <FormButtons />
            </SoleTraderForm>
          )}
          <Modal
            isOpen={addingDriver}
            handleClose={async () => {
              if (currentModifyingDriverIndex >= 0) {
                await setCurrentDriverDetails(DefaultValuesDetailsForm);
              }
              setCurrentModifyingDriverIndex(-1);

              setAddingDriver(false);
              // console.log(currentDriverDetails);
            }}
          >
            <h2 className="box-border py-2 font-bold text-xl">
              Driver Details
            </h2>
            <div className="w-full">
              <div className="h-[60vh] overflow-scroll overflow-x-hidden box-border px-4">
                <DriverAddForm
                  onSubmit={async (e) => {
                    await setCurrentDriverDetails({
                      ...DefaultValuesDetailsForm,
                    });
                    setAddingDriver(false);
                    await setAllDriverDetails((prev) => {
                      if (currentModifyingDriverIndex >= 0) {
                        prev.splice(currentModifyingDriverIndex, 1, {
                          ...e,
                          customerId: currentCustomerDetails.customerId,
                          reservationNumber:
                            reservationDetails.reservationId.toString(),
                        });
                        return [...prev];
                      }
                      return [
                        ...prev,
                        {
                          ...e,
                          customer_id: currentCustomerDetails.customerId,
                          reservationNumber:
                            reservationDetails.reservationId.toString(),
                        },
                      ];
                    });
                    setCurrentModifyingDriverIndex(-1);
                  }}
                  driverDetails={currentDriverDetails}
                  initialValues={
                    currentModifyingDriverIndex < 0
                      ? DefaultValuesDetailsForm
                      : initialDriverValues[currentModifyingDriverIndex]
                  }
                  onChange={(e) => {
                    // console.log(e);

                    setCurrentDriverDetails({
                      ...currentDriverDetails,
                      ...e,
                    });
                  }}
                >
                  <div className="flex justify-end mr-10 items-center">
                    <AppStandardButton type="submit" className={"w-1/4"}>
                      {t("Save")}
                    </AppStandardButton>
                  </div>
                </DriverAddForm>
              </div>
            </div>
          </Modal>
        </div>
      ),
      onSubmit: async () => {
        await setIsSaving(true);
        await BookingApi.updateCustomerDetails(
          reservationDetails.customerId,
          currentCustomerDetails
        );
        await setCurrentStep(currentStep + 1);
        await setIsSaving(false);
      },
    },

    {
      label: t("Customer Documents"),
      component: (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsSaving(true);
            BookingApi.addDocuments(
              parseInt(initialCustomerDetails.customerId),
              document
            )
              .then(() => {
                return BookingApi.addDrivers(reservationDetails.reservationId, [
                  allDriverDetails[0],
                  allDriverDetails[1],
                  allDriverDetails[2],
                ]);
              })
              .then(() => {
                navigate("/SuccessPage");
              })
              .finally(() => {
                setIsSaving(false);
              });
            BookingApi.savePrivacyPolicy(
              reservationDetails.reservationId,
              selectedPrivacyPolicy.map((el, index) => {
                return (
                  allPrivacyPolicy[index].find(
                    (e) => e.privacyPolicyLocalLang == el
                  )?.id || -1
                );
              })
            );
          }}
        >
          <>
            {allDriverDetails.map((el, index) => {
              // console.log(el);

              return (
                <div key={index}>
                  <p className="text-base font-semibold margin">
                    {t(`Document For Driver`)} {index + 1}
                  </p>
                  <AddDocumentsWithPreview
                    onChange={(files) => {
                      setAllDriverDetails((e) => {
                        const temp = [...e];
                        temp[index].documents = files;
                        // console.log(el);
                        return [...temp];
                      });
                    }}
                    selectedDocs={el?.documents || []}
                  />
                </div>
              );
            })}{" "}
          </>
          <p className="text-base font-semibold margin">
            {t("Document For Customer")}
          </p>
          <AddDocumentsWithPreview
            onChange={(document) => {
              setDocument(document);
              //console.log(document);
            }}
          >
            <p className="mt-8 font-semibold">
              Privacy policy and Terms & condition
              <sup className="font-semibold text-red-500">*</sup>
            </p>
            <AppRadioButton
              className=" font-semibold pt-2 "
              required={true}
              options={[]}
            />
            {/* <div>
              <Switch></Switch>
            </div> */}
            {allPrivacyPolicy.map((e, index) => {
              return (
                <>
                  <span className=" text-blue-250 ">
                    {" "}
                    Privacy policy {index + 1}
                  </span>

                  <AppRadioButton
                    key={index}
                    required={true}
                    value={selectedPrivacyPolicy[index] || ""}
                    options={e
                      .map((e) => e.privacyPolicyLocalLang || "")
                      .filter((e) => !!e)}
                    onChange={(e) => {
                      setSelectedPrivacyPolicy((prev) => {
                        prev[index] = e;
                        return [...prev];
                      });
                    }}
                  />
                </>
              );
            })}
            <FormButtons />
          </AddDocumentsWithPreview>
        </form>
      ),
      onSubmit: async () => {},
    },
  ];

  return (
    <div
      className={
        "flex flex-col justify-center items-center w-full box-border px-8 gap-4"
      }
    >
      <div>
        <AppRadioButton
          readOnly={currentStep > 0}
          label={t("select your reservation")}
          value={t(reservationType)}
          options={ReservationOptions.map((el: RESERVATION_TYPE) =>
            t(el).toString()
          )}
          onChange={(e) => {
            setReservationType(e as RESERVATION_TYPE);
            setErrorMessage("");
          }}
        />
      </div>
      <AlertDialog
        onCancel={() => {
          setOpenDriverAddDialog(false);
          setCurrentStep(2);
        }}
        title={`Do you want to add Driver ${allDriverDetails.length + 1}?`}
        onClose={() => {
          setOpenDriverAddDialog(false);
        }}
        open={openDriverAddDialog}
        onConfirm={() => {
          setAddingDriver(true);
          setOpenDriverAddDialog(false);
        }}
      />
      <div className={"md:w-[50%] md:p-4 p-0 w-full"}>
        <StandardCard className={" box-border md:p-4 p-0 flex flex-col"}>
          <div className="w-full box-border py-8 overflow-auto">
            <Stepper activeStep={currentStep}>
              {allStep.map((el, index) => {
                return (
                  <Step key={index}>
                    <StepLabel color="primary-2">{el.label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div className="flex flex-col  overflow-auto w-full grow shrink">
            {allStep.map((el, index) => {
              return (
                index === currentStep && (
                  <div className="" key={index}>
                    <div className="box-border p-4 flex flex-col gap-2">
                      {el.component}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </StandardCard>
      </div>
      <Dialog
        open={openModal}
        onClose={onRequestClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{
            paddingLeft: "4rem",
            paddingRight: "4rem",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            color: "red",
          }}
        >
          {modalMessage}
        </DialogTitle>

        <DialogActions>
          <AppStandardButton variant="outlined" onClick={onRequestClose}>
            <p className="text-black normal-case">Close</p>
          </AppStandardButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export { WebCheckInPage };
