import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AppStandardTabViewProps } from "./AppTabView.props";
import React, { useState } from "react";
import BookingGetters from "../../../store/onlineBooking/getters";

StandardTabView.propTypes = {
  ...AppStandardTabViewProps,
};
StandardTabView.defaultProps = {
  handleChange: () => {},
  activeTabValue: "0",
};
function StandardTabView({ tabs, handleChange, activeTabValue }) {
  const AppSettings = BookingGetters.getAppSettings();
  const [donotShowInsurance, setDonotShowInsurance] = useState<boolean>(
    !!AppSettings?.donotShowInsurance
  );
  const [donotShowExtras, setDonotShowExtras] = useState<boolean>(
    !!AppSettings?.donotShowExtras
  );
  return (
    <TabContext value={activeTabValue}>
      <div className={"sticky top-0 bg-white z-[2]"}>
        <TabList
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          onChange={handleChange}
        >
          {tabs.map((value, index) => {
            const hideTab =
              (value.title === "Aggiungi Protezioni" ||
                value.title === "Add Protections") &&
              donotShowInsurance == true;
            const hideExtrasTab =
              (value.title === "Aggiungi extra" ||
                value.title === "Add Extras") &&
              donotShowExtras == true;
            if (hideExtrasTab) {
              return null;
            }
            if (hideTab) {
              return null;
            }
            return <Tab label={value.title} value={`${index}`} key={index} />;
          })}
        </TabList>
      </div>
      {tabs.map((value, index) => {
        return (
          <TabPanel value={`${index}`} key={index}>
            {value.content}
          </TabPanel>
        );
      })}
    </TabContext>
  );
}

export { StandardTabView as default };
export { StandardTabView };
