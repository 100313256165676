import AppStoreLinks from "../../UI/AppStoreLinks";
import { SocialMediaLinks } from "../../UI/SocialMediaLinks";
import { AppFooterProps } from "./AppFooter.props";
import { PageConfigGetters } from "../../../store/PageConfigurations/getters";
import React from "react";

export default function AppFooter({
  className = "",
}: AppFooterProps): JSX.Element {
  const FooterConfig = PageConfigGetters.getAppFooterConfig();

  return (
    <footer
      className={
        "flex flex-row flex-wrap box-border p-4" +
        " " +
        "min-h-12" +
        " " +
        className
      }
    >
      <aside className="logo-container flex flex-col">
        <div className="max-h-48 max-w-48 box-border p-4">
          <img className="h-full w-full grow" src={FooterConfig.logoUrl} />
        </div>
        <div className="social-media-links-container w-full">
          <SocialMediaLinks {...FooterConfig.socialMediaLinks} />
        </div>
      </aside>
      <main className="grow"></main>
      {FooterConfig.appStoreDetails && (
        <aside>
          <h3 className="text-sky-600 font-bold text-lg box-border pb-4">
            Mobile App Download
          </h3>
          <AppStoreLinks
            className={"h-[12rem]"}
            links={FooterConfig.appStoreDetails}
          />
        </aside>
      )}
    </footer>
  );
}
