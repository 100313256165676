import { LoadingButton } from "@mui/lab";
import { func } from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingApi from "../../API/Apis/BookingApi";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import ReservationCard from "../../components/Layouts/ReservationsCard/ReservationsCards";
import { AppStandardButton } from "../../components/UI/AppButtons";
import NotAuthorisedPage from "../notAuthorisedPage";

export default function MyReservationsPage(props) {
  // const userId = localStorage.getItem(`userID`);
  // console.log(userId);

  const [openTab, setOpenTab] = useState(1);
  const [showReserve, setShowReserve] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    BookingApi.getAppSetting().then((e) => {
      setShowReserve(e.reservedArea);
      // setShowReserve(true);
    });
  }, [showReserve]);

  // const [testData, setTestData] = useState([]);
  // const [datastest, setDatasTest] = useState([]);
  // useEffect(() => {
  //   const id = localStorage.getItem("userID");
  //   BookingApi.getBookingDetailsbyUserId(id).then((e) => {
  //     setTestData(e.data.datas);
  //     e.data.datastest.forEach((e) => {
  //       console.log(e);
  //     });
  //     setDatasTest(e.data.datastest);

  //   });
  // }, [testData]);

  return (
    <div>
      <AppStandardButton
        href="/"
        className={"flex justify-end mr-1"}
        sx={{
          width: 200,
          "& .css-9pkl4c-MuiButtonBase-root-MuiButton-root": {
            textAlign: "end",
          },
        }}
        variant="contained"
        color="chooseVehiclePayNowButton"
        style={{ borderRadius: "5" }}
      >
        {/* Go to Account */}
        <span className="normal-case text-lg"> Book a Car</span>
      </AppStandardButton>
      <div className="flex mt-12 mr-6 h-480 max-h-screen ">
        <BookingDetalisListSideNavigation />
        {showReserve ? (
          <div>
            <div className="container  mx-4">
              <div className="flex flex-col   ">
                <div className="flex justify-between space-x-2 mb-8  ">
                  <div>
                    <h1 className="font-medium text-[36px] text-[#232323]">
                      Booking Details
                    </h1>
                    <h4 className="text-[#838181] font-normal text-[20px]">
                      Here is your booking details
                    </h4>
                  </div>

                  <ul className="flex space-x-2 mb-12 absolute right-0">
                    <li>
                      <a
                        href="#"
                        onClick={() => setOpenTab(1)}
                        variant="active"
                        className={` ${
                          openTab === 1 ? "bg-[#020D53] text-[#fff]" : ""
                        } ${"inline-block px-4 py-2 w-40  h-70 text-gray-600  text-center rounded shadow"}`}
                      >
                        Confirm
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => setOpenTab(2)}
                        className={` ${
                          openTab === 2 ? "bg-[#020D53]  text-[#fff]" : ""
                        } inline-block px-4 py-2 w-40 h-70 text-gray-600 text-center rounded shadow`}
                      >
                        Pending
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => setOpenTab(3)}
                        className={` ${
                          openTab === 3 ? "bg-[#020D53]  text-[#fff]" : ""
                        } inline-block px-4 py-2 w-40  h-70  text-gray-600 text-center  rounded shadow`}
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </div>
                <div className={openTab === 1 ? "block" : "hidden"}>
                  <ReservationCard></ReservationCard>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"}>
                  <ReservationCard></ReservationCard>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"}>
                  <ReservationCard></ReservationCard>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NotAuthorisedPage />
        )}
      </div>
    </div>
  );
}
