import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import BookingApi from "../../API/Apis/BookingApi";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { AppStandardButton } from "../../components/UI/AppButtons";

interface Column {
  id: "name" | "surname" | "companyName" | "email" | "mobileNumber";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "surname", label: "Surname", minWidth: 100 },
  {
    id: "companyName",
    label: "Company Name",
    minWidth: 170,
    align: "right",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    align: "right",
  },
  {
    id: "mobileNumber",
    label: "Mobile",
    minWidth: 170,
    align: "right",
  },
];

interface Data {
  name: string;
  surname: string;
  companyName: string;
  email: string;
  mobileNumber: number;
}

function createData(
  name: string,
  surname: string,
  companyName: string,
  email: string,
  mobileNumber: number
): Data {
  return { name, surname, companyName, email, mobileNumber };
}

export default function CustomerDetailsList() {
  const navigateTo = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [data, setCustomerDetails] = useState({
    id: "",
    name: "",
    surname: "",
    companyName: "",
    email: "",
    mobileNumber: 0,
  });

  const rows = [
    createData(
      data.name,
      data.surname,
      data.companyName,
      data.email,
      data.mobileNumber
    ),
  ];
  const Clicked = () => {
    localStorage.setItem("customerId", data.id);

    navigateTo("/updateCustomer");
  };

  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    BookingApi.getCustomerDeatilsByUserid(id).then((e) => {
      e.data.customerList.forEach((data) => {
        setCustomerDetails(data);
      });
    });
  }, []);

  return (
    <div>
      <AppStandardButton
        href="/"
        className={"flex justify-end mr-1"}
        sx={{
          width: 200,
          "& .css-9pkl4c-MuiButtonBase-root-MuiButton-root": {
            textAlign: "end",
          },
        }}
        variant="contained"
        color="chooseVehiclePayNowButton"
        style={{ borderRadius: "5" }}
      >
        {/* Go to Account */}
        <span className="normal-case text-lg"> Book a Car</span>
      </AppStandardButton>
      <div className="flex">
        <div className="!w-780">
          <BookingDetalisListSideNavigation />
        </div>
        <div className="w-[72%]">
          <div className="text-[#333] px-12 max-w-screen-xl m-auto">
            <h1 className="text-[36px] font-medium">Customer Details</h1>
            <h3 className="text-[#838181] text-[20px]">
              Here is your customer details
            </h3>
          </div>
          <TableContainer className="  !shadow-grey-400 !rounded-2xl !border-solid !border-3 p-8">
            <Table
              className="!shadow-2xl  rounded-2xl !border-solid !border-2 !border-grey-400"
              aria-label="simple table"
              stickyHeader
            >
              <TableHead className="!bg-[#020D53] text-[24px] ">
                <TableRow className="!bg-[#020D53] mx-12 ">
                  <TableCell className="!bg-[#020D53] !text-[#fff] !text-[24px] ">
                    Full name
                  </TableCell>
                  <TableCell className="!bg-[#020D53] !text-[#fff] !text-[24px]">
                    E-mail
                  </TableCell>
                  <TableCell className="!bg-[#020D53] !text-[#fff] !text-[24px]">
                    Phone
                  </TableCell>
                  <TableCell
                    align="center"
                    className="!bg-[#020D53] !text-[#fff] !text-[24px] pl-12"
                  >
                    Company name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody onClick={Clicked}>
                <TableRow key={data.id}>
                  <TableCell className="!text-[16px]" component="th" scope="e">
                    <ul>
                      <li>
                        {data.name || "No Data"} {data.surname}
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell className="!text-[16px]">
                    {data.email || "No Data"}
                  </TableCell>

                  <TableCell className="!text-[16px]">
                    {data.mobileNumber || "No Data"}
                  </TableCell>
                  <TableCell align="center" className="!text-[16px]">
                    {data.companyName || "No Data"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
