import { AppStandardButton } from "../../UI/AppButtons";
import React from "react";
import { t } from "i18next";

export function SignUpSuccess() {
  return (
    <section className="min-h-[80vh] box-border px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1 className="text-3xl font-bold text-[#004188] py-2">
          {t("Thank you! Your Registration Is Successful")}
        </h1>
        <p className="font-bold text-black-600">
          {/* Reservation Code:{" "} */}
          {/* Web Check In Code:{" "}
            <span className="font-bold text-[#5cbc14]">DG-87450</span> */}
        </p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/"
            className={"w-fit py-4 mx-8"}
            variant="contained"
            color="webCheckInNextButton"
            style={{ borderRadius: "5" }}
          >
            {/* Go to Account */}
            <span className="normal-case text-[#ffffff] p-1 text-lg">
              {" "}
              Book a Car
            </span>
          </AppStandardButton>
          <AppStandardButton
            href="/signup"
            className={"w-fit py-4 mx-8"}
            color="webCheckInNextButton"
            variant="outlined"
            style={{ borderRadius: "5" }}
          >
            {/* Go to Account */}
            <span className="normal-case text-[#25ad5f] p-1 text-lg">
              {" "}
              Dashboard
            </span>
          </AppStandardButton>
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        {/* Web Checkin Info */}
        {/* Web Checkin Information */}

        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
