import { makeStyles } from "@mui/styles";

const useBreadCrumbStyles = makeStyles((theme) => ({
  default() {
    return {
      "&.MuiBreadcrumbs-root": {
        color: "white",
        userSelect: "none",
      },
    };
  },
}));
export { useBreadCrumbStyles };
