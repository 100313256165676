import { CloseTwoTone, DoneTwoTone } from "@mui/icons-material";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import { AppStandardButton as AppButton } from "../../UI/AppButtons";
import { InsuranceCardProps } from "./Insurance.props";
import React from "react";
import { t } from "i18next";

// InsuranceCardsLayout.propTypes = {
//   ...InsuranceCardsLayoutProps,
// };
// InsuranceCardsLayout.defaultProps = {
//   onSelected: () => {},
// };

function InsuranceCardsLayout({
  insurances,
  onSelected,
  selectedInsuranceIndex = -1,
}: InsuranceCardProps) {
  const allInsuranceFeatures: Record<string, boolean> = {};
  const allInsurances = insurances.map((el) => {
    const availableInsurances = el.features.reduce((prev, current) => {
      const feature = current.toLowerCase();
      prev[feature] = true;
      allInsuranceFeatures[feature] = true;
      return prev;
    }, {});
    return { ...el, availableInsurances };
  });
  // console.log(allInsurances);

  const secondaryTextColor = " text-blue-600 ";
  const border = " border-[0.5px] border-solid border-slate-600 ";
  return (
    <table className="table-auto  border-collapse text-black sm:w-full">
      <thead>
        <tr className={border}>
          <th className={border + ""}>
            <h3>Tabella dei prezzi</h3>
            {/* <p className="text-grey-200 font-light">Lorem Ipsum</p> */}
          </th>

          {allInsurances.map((el, index) => (
            <th
              className={border + "sm:min-w-auto h-20 sm:relative"}
              key={index}
            >
              {el.isRecommended && (
                <div className="text-grey-200 font-light">
                  <img
                    src="/assets/images/Favo-icon@2x.png"
                    className="h-12 w-12 top-0 absolute"
                  />
                </div>
              )}
              <h5 className="sm:text-sm">{el.name}</h5>
              <p className={secondaryTextColor + "sm:text-md font-normal"}>
                {formatPriceWithCurrencySymbol(BookingGetters.getCurrency())
                  .symbol + " "}
                {el.pricePerDayInUSD}
                {/* <span className="text-sm">/day</span> */}
              </p>
              {/*<p>{el.description}</p>*/}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="overflow-scroll w-full">
        {Object.keys(allInsuranceFeatures).map((el, index) => {
          return (
            <tr
              key={index}
              className={border + "sm:font-semibold text-sm capitalize"}
            >
              <td className={border + " sm:min-w-[12rem] box-border p-1"}>
                {el}
              </td>

              {allInsurances.map((insurance, i) => {
                const text =
                  insurance.bookingText &&
                  insurance.bookingText[
                    el.toUpperCase() + ":" + insurance.name.toUpperCase()
                  ];
                if (text != null && text !== "undefined" && text !== "") {
                  return (
                    <td className={border + ""} key={i}>
                      <p className="h-full w-full flex justify-center items-center">
                        {text}
                      </p>
                    </td>
                  );
                } else if (
                  insurance.availableInsurances &&
                  insurance.availableInsurances[el]
                ) {
                  return (
                    <td className={border + ""} key={i}>
                      <p className="h-full w-full flex justify-center items-center">
                        <DoneTwoTone className="text-green-800 font-bold" />
                      </p>
                    </td>
                  );
                } else {
                  return (
                    <td className={border} key={i}>
                      <p className="h-full w-full flex justify-center items-center">
                        <CloseTwoTone className="text-red-800" />
                      </p>
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot className="">
        <tr>
          <td className={border + "w-36 h-20"}>
            <AppButton
              clickHandler={() => {
                onSelected(-1);
              }}
              color="red"
              className="box-border px-6 text-white"
            >
              {/* Cancel */}
              {t("Cancel")}
            </AppButton>
          </td>
          {allInsurances.map((el, index) => (
            <td className={border + "relative"} key={index}>
              <AppButton
                color={selectedInsuranceIndex == index ? "success" : "primary"}
                clickHandler={() => {
                  onSelected(index);
                }}
                className="box-border px-6"
              >
                {/* Choose */}
                {t("Choose")}
              </AppButton>
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}
export { InsuranceCardsLayout };
