import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import React from "react";

function VehicleExtraOptions({
  iconUrl,
  optionName,
  pricePerDayInUSD,
  details,
  onDecrementClick,
  onIncrementClick,
  value,
}): JSX.Element {
  const buttonBorderStyles =
    "border-solid border-gray-300 border-2 rounded-lg ";
  return (
    <div className="flex flex-row w-full h-full relative">
      <aside className="flex flex-row items-center justify-center w-[35%]">
        {iconUrl != null && iconUrl != "#" ? (
          <img src={iconUrl} className="w-full aspect-square" alt="Icon" />
        ) : (
          <span></span>
        )}
      </aside>

      <main className="flex flex-col box-border px-4 grow max-w-[80%] grow text-gray-500 text-sm relative">
        <span className="text-gray-500 font-bold break-words ">
          {optionName}
        </span>
        <span
          className={"break-words grow  overflow-hidden hover:overflow-auto"}
        >
          {details}
        </span>
        <span className="bottom-0 relative">
          {formatPriceWithCurrencySymbol(BookingGetters.getCurrency()).symbol}
          {pricePerDayInUSD}
        </span>
      </main>
      <aside className="flex flex-col gap-2 h-full">
        <div
          onClick={() => {
            onIncrementClick();
          }}
          className={
            "w-6 h-6 flex items-center text-center justify-center  cursor-pointer select-none " +
            " " +
            buttonBorderStyles
          }
        >
          <span className="w-fit h-fit">+</span>
        </div>
        <span className="grow flex items-center justify-center ">{value}</span>
        <div
          onClick={() => {
            onDecrementClick();
          }}
          className={
            "w-6 h-6 flex items-center text-center justify-center cursor-pointer select-none" +
            " " +
            buttonBorderStyles
          }
        >
          -
        </div>
      </aside>
    </div>
  );
}
export { VehicleExtraOptions };
export { VehicleExtraOptions as default };
