import React from "react";
import { useNavigate } from "react-router-dom";
// import { LogoutOutlined } from "@mui/icons-material";

// const MenuOptions = [
//   {
//     label: "Logout",
//     iconUrl: <LogoutOutlined />,
//     clickHandler: () => {},
//   },
// ];

function ProfileNavigation() {
  const navigateTo = useNavigate();
  const isLogged = localStorage.getItem("isUserLoggedin");

  function onLogout() {
    if (isLogged == "true") {
      console.log("user is logged out ");
      localStorage.setItem("isUserLoggedin", "false");
      localStorage.removeItem("userID");
      localStorage.removeItem("customerId");
      alert("Successfully Logged Out");
      navigateTo("/signin");
    } else {
      alert("you are already logged out");
    }
  }
  return (
    <div className="text-[#020D53] mx-12 mt-4">
      <div>
        <ul>
          <li className="text-[32px] tracking-wide font-medium">My Profile</li>
        </ul>
      </div>
      <div>
        <ul className="flex flex-col gap-4 mt-8 text-[20px]">
          <a className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2" href="">
            Personal information
          </a>
          <a className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2" href="">
            Change password
          </a>
          <a className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2" href="">
            Payment method
          </a>
          <a
            onClick={onLogout}
            className="hover:bg-[#EFEFEF] rounded-2xl w-200 p-2"
            href=""
          >
            Logout
          </a>
        </ul>
      </div>
    </div>
  );
}

export default ProfileNavigation;
